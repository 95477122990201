<template>
  <div>
    <div
      id="cert"
      class="cert-wrapper attendee-cert"
      v-if="currentPeople.type != 'speaker'"
    >
      <span class="people-name">{{ peopleName }}</span>
    </div>
    <div
      class="cert-wrapper speaker-cert"
      v-if="currentPeople.type == 'speaker'"
    >
      <span class="people-name">{{ peopleName }}</span>
    </div>
    <!--
    <button class="btn btn-primary btn-lg download-btn" @click="generatePDF()">
      İndir
    </button>
    -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import html2pdf from 'html2pdf.js';

export default {
  computed: {
    ...mapGetters({
      currentPeople: 'people/current',
    }),
    peopleName() {
      if (this.currentPeople.lastName !== undefined) {
        return `${this.currentPeople.name} ${this.currentPeople.lastName}`;
      }
      return this.currentPeople.name;
    },
  },
  methods: {
    generatePDF() {
      // Choose the element that our invoice is rendered in.
      const element = document.getElementById('cert');
      const opt = {
        margin: 1,
        filename: 'certificate.pdf',
        image: { type: 'jpeg' },
        html2canvas: { scale: 1 },
        jsPDF: { format: 'letter', orientation: 'l' },
      };
      // Choose the element and save the PDF for our user.
      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
  created() {
    console.log(this.currentPeople);
  },
};
</script>

<style lang="scss">
.cert-wrapper {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  background-position: center;
  max-width: calc(100vh * 1.4);
  margin: auto;
}
.attendee-cert {
  background-image: url("../assets/images/sertifika.png");
}
.speaker-cert {
  background-image: url("../assets/images/sertifika_speaker.png");
}
.people-name {
  font-size: calc(100vh / 21);
  padding-bottom: calc(100vh / 16);
  color: #000;
  font-style: italic;
  font-weight: 600;
  font-family: serif;
}
.download-btn {
  position: fixed;
  top: 16px;
  right: 16px;
}
</style>
