<template>
  <div class="home">
    <!--<Header />-->
    <Hero />
    <Agenda style="margin-top: 32px" />
  </div>
</template>

<script>
// @ is an alias to /src
// import Header from '@/components/Header.vue';
import Hero from '@/components/Hero.vue';
import Agenda from '@/components/Agenda.vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'Home',
  components: {
    Agenda,
    Hero,
  },
  data() {
    return {
      isReady: false,
      certificateEnabled: false,
      isCertDownloading: false,
    };
  },
  computed: {
    ...mapGetters({
      liveSession: 'agenda/liveSession',
      eventCurrentPath: 'event/currentPath',
      currentPeople: 'people/current',
      currentAccount: 'account/current',
      currentEvent: 'event/current',
    }),
  },
  methods: {
    ...mapActions({
      setCurrent: 'event/setCurrent',
      getEvent: 'event/get',
      getCertificate: 'people/getCertificateUrl',
    }),
    tabClick() {},
    goBack() {
      this.$router.push('/');
    },
    enableCertificate() {
      this.certificateEnabled = false;
      const sesTime = moment();
      const enableTime = moment('13 02 2021 18:00', 'DD MM YYYY hh:mm');

      if (enableTime.isBefore(sesTime)) {
        this.certificateEnabled = true;
      } else {
        this.certificateEnabled = false;
      }
    },
    downloadCert() {
      this.isCertDownloading = true;
      this.getCertificate().then((res) => {
        this.isCertDownloading = false;
        console.log(res);
      });
    },
  },
  created() {
    this.enableCertificate();
  },
};
</script>

<style lang="scss" scoped>
.box {
  &.live {
    h2 {
      color: #dc3545;
    }
  }
}
</style>
