<template>
  <component :is="component" v-if="component" />
</template>

<script>
import hPHhFb0wIIVZhZ5Kjtye from '@/views/booths/hPHhFb0wIIVZhZ5Kjtye.vue';
import BP642iTGBimehU72fq91 from '@/views/booths/BP642iTGBimehU72fq91.vue';
import hXiUwxXwLlebUMLRpqlw from '@/views/booths/hXiUwxXwLlebUMLRpqlw.vue';
import qallEdl27vvj5WabTtsQ from '@/views/booths/qallEdl27vvj5WabTtsQ.vue';
import kBbRJGQ00jCbg54qCCfk from '@/views/booths/kBbRJGQ00jCbg54qCCfk.vue';
import nKAFNvJ2bcNcdm0fMgK9 from '@/views/booths/novartis.vue';
import s8z0x4xQpzNe0CrVLsMh from '@/views/booths/s8z0x4xQpzNe0CrVLsMh.vue';
import LIXXeQdvEKfJwDUWoUFx from '@/views/booths/LIXXeQdvEKfJwDUWoUFx.vue';

export default {
  data() {
    return {
      component: null,
    };
  },
  components: {
    hPHhFb0wIIVZhZ5Kjtye,
    BP642iTGBimehU72fq91,
    hXiUwxXwLlebUMLRpqlw,
    qallEdl27vvj5WabTtsQ,
    kBbRJGQ00jCbg54qCCfk,
    nKAFNvJ2bcNcdm0fMgK9,
    s8z0x4xQpzNe0CrVLsMh,
    LIXXeQdvEKfJwDUWoUFx,
  },
  computed: {},
  mounted() {
    this.component = this.$options.components[this.$route.params.id];
  },
};
</script>

<style>
</style>
